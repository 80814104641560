if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  // this should be used only for dev, but they say it wont be a problem in prod too:
  // https://github.com/facebook/create-react-app/issues/2603
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

require('core-js/stable');
require('regenerator-runtime/runtime');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
// babel/polyfill object-assign checks only 1 V8 issue, this package checks 3 V8 issues
Object.assign = require('object-assign');

require('events-polyfill/index.min');

require('immer').enableES5();
